var render = function render(){var _vm=this,_c=_vm._self._c;return _c(_vm.linkDisabled ? 'span' : 'router-link',{tag:"component",staticClass:"w-full",attrs:{"to":{
    name: 'meeting',
    params: { id: _vm.event.e.id, calendarId: _vm.event.cid || '' },
    query: { private: _vm.showPrivate ? null : undefined },
  }}},[_c('m-card',{staticClass:"event-panel",class:{
      'event-highlight': _vm.event.e.id == _vm.highlight,
      'cursor-pointer': !_vm.linkDisabled,
    }},[_c('div',{staticClass:"lg:flex justify-between"},[_c('div',{staticClass:"flex lg:justify-start items-start lg:items-center mt-1 mb-1",staticStyle:{"min-width":"0"}},[_c('div',{staticClass:"text-grey1 lg:ml-3 font-normal text-xl lg:truncate max-w-sm",class:_vm.status == 'pastEvent' ? 'opacity-50' : '',attrs:{"title":_vm.event.e.summary,"data-recording-sensitive":""}},[_vm._v(" "+_vm._s(_vm.event.e.summary ? _vm.event.e.summary : '(No title)')+" ")]),_c('div',{staticClass:"flex flex-grow lg:flex-grow-0"}),(_vm.meetricInfo)?_c('div',{staticClass:"flex ml-1 mt-1 lg:mt-0 mr-0 lg:mr-4"},[(
              _vm.meetricInfo.public.has_notes || _vm.meetricInfo.private.has_notes
            )?_c('MCINotes'):_vm._e(),(
              _vm.meetricInfo.public.actions.total > 0 ||
              _vm.meetricInfo.private.actions.total > 0
            )?_c('MCIActions',{attrs:{"actionsTotal":_vm.meetricInfo.public.actions.total +
              _vm.meetricInfo.private.actions.total,"actionsCompleted":_vm.meetricInfo.public.actions.complete +
              _vm.meetricInfo.private.actions.complete}}):_vm._e()],1):_c('div',{staticClass:"flex ml-4"},[_c('m-placeholder',{staticClass:"ml-1 h-5 w-16 rounded"})],1)]),_c('div',{staticClass:"lg:hidden my-2 font-medium"},[_c('div',{staticClass:"my-auto event-time text-grey3",class:_vm.event.e.id == _vm.highlight ? 'event-highlight' : '',attrs:{"data-recording-sensitive":""}},[_vm._v(" "+_vm._s(_vm.eventTime)+" ")])]),_c('div',{staticClass:"hidden lg:flex mt-1 mr-2"},[_c('meeting-guests',{attrs:{"guests":_vm.guests,"eventStatus":_vm.status,"showOnlineStatus":false,"showDetails":true}})],1)])])],1)
}
var staticRenderFns = []

export { render, staticRenderFns }